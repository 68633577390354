import { IBulkEditMenuItemRequest, IEffectiveDates, IScheduleLink, Status, IBulkEditMenuItem } from '@ready/menu.core';
import Checkbox from 'components/Checkbox/Checkbox';
import { Form, FormControl } from 'components/Form';
import { ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import Modal from 'components/Modal/Modal';
import Toggle from 'components/Toggle/Toggle';
import { EffectiveDates } from 'menus/components/ItemsAndMods/MenuItemDetails/components/EffectiveDates';
import ScheduleFormControl from 'menus/components/shared/ScheduleFormControl';
import { ILink } from 'menus/redux/MenusState';
import { selectScheduleListState } from 'menus/redux/SchedulesSelectors';
import React, { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { selectTableState } from 'sharedMenuItems/redux/selectors';
import { setBulkLocationEditModalVisible } from 'sharedMenuItems/redux/table/tableSlice';
import { bulkEditLocationsThunk } from 'sharedMenuItems/redux/table/tableThunkActions';
import { ContextParams } from 'types/ContextParams.interface';
import styles from './BulkLocationEditModal.module.scss';
import { PosMenuItemSelect, IPosMenuItemOption } from 'sharedMenuItems/components/PosItemSelect/PosMenuItemSelect';

export interface IFormState {
  isPopular?: boolean;
  status?: Status;
  effectiveDates?: IEffectiveDates;
  schedule?: IScheduleLink<ILink>;
  posItemId?: string;
}

interface IFormCheckboxStates {
  status: boolean;
  schedule: boolean;
  effectiveDates: boolean;
  popular: boolean;
  posItem: boolean;
}

type Props = {
  resetPageFilters?: () => void;
};

const isPosTypeSameForAllSelectedItems = (
  checkCells: { [key: string]: boolean | undefined },
  items: IBulkEditMenuItem[]
) => {
  const filteredResults = items.filter((item) => checkCells[item.locationId]);

  return filteredResults.every((item) => item?.posType === filteredResults[0]?.posType);
};

const BulkLocationEditModal = ({ resetPageFilters }: Props) => {
  const dispatch = useAppDispatch();
  const { contextId: companyId, id: templateItemId } = useParams<ContextParams>();
  const { schedules, loading: schedulesLoading } = useAppSelector(selectScheduleListState);
  const { checkCells, allMenuItemsSelected } = useAppSelector(selectTableState);
  const { items, pagination } = useAppSelector((state) => state.sharedMenuItems.itemAssignedLocationsList);
  const locationIds = Object.keys(checkCells);
  const numOfCheckedCells = Object.values(checkCells).filter((value) => value).length;
  const { itemCache } = useAppSelector((state) => state.sharedMenuItems.item);
  const isModifier = itemCache?.itemType === 'option';
  const showPosItemEdit = isPosTypeSameForAllSelectedItems(checkCells, items);

  const [formState, setFormState] = React.useState<IFormState>({
    isPopular: false,
    status: 0,
    effectiveDates: { start: '', end: '' },
    posItemId: '',
  });

  const [checkboxStates, setCheckboxStates] = React.useState<IFormCheckboxStates>({
    status: false,
    schedule: false,
    effectiveDates: false,
    popular: false,
    posItem: false,
  });

  const [saving, setSaving] = useState<boolean>(false);
  const onSaveChanges = async () => {
    setSaving(true);
    const request: IBulkEditMenuItemRequest = {
      items: [],
      itemUpdates: {},
      templateItemId: templateItemId,
    };

    const selectedIds = locationIds.filter((id) => checkCells[id]);
    selectedIds.forEach((locationId: string) => {
      const itemId = items.find((item) => item.locationId === locationId)?._id;
      if (itemId) request.items?.push({ _id: itemId, locationId });
    });

    if (checkboxStates.status) request.itemUpdates.status = formState.status;
    if (checkboxStates.schedule) request.itemUpdates.schedule = formState.schedule;
    if (checkboxStates.effectiveDates) request.itemUpdates.effectiveDates = formState.effectiveDates;
    if (checkboxStates.popular) request.itemUpdates.isPopular = formState.isPopular;
    if (checkboxStates.posItem) request.itemUpdates.posItemId = formState.posItemId;

    await dispatch(bulkEditLocationsThunk({ companyId, templateItemId, request }));
    resetPageFilters && resetPageFilters();
    setSaving(false);
    dispatch(setBulkLocationEditModalVisible(false));
  };

  return (
    <Modal setShowModal={setBulkLocationEditModalVisible}>
      <ModalHeader
        headerLabel={`Edit ${isModifier ? 'Modifier' : 'Item'} Settings`}
        subLabel={
          allMenuItemsSelected
            ? `(${pagination?.total} locations)`
            : numOfCheckedCells === 1
            ? '(1 location)'
            : `(${numOfCheckedCells} locations)`
        }
        subHeader={'Select the fields you would like to edit. Unselected fields will remain unchanged.'}
        additionalStyles={styles.header}
        additionalSubLabelStyles={styles.subLabel}
        setShowModal={(visible) => dispatch(setBulkLocationEditModalVisible(visible))}
      />
      <ModalBody>
        <Form hasGroups isModalForm>
          <div className={styles.formRow}>
            <Checkbox
              label=''
              checked={checkboxStates.status}
              onChange={() => setCheckboxStates((prev) => ({ ...prev, status: !checkboxStates.status }))}
              disabled={saving}
            />

            <FormControl label='Status' disabled={!checkboxStates.status}>
              <Toggle
                checked={!!formState.status}
                disabled={!checkboxStates.status || saving}
                onChange={(checked: boolean) => {
                  setFormState((prev) => ({ ...prev, status: checked ? 1 : 0 }));
                }}
              />
            </FormControl>
          </div>

          {!isModifier && (
            <div className={styles.formRow}>
              <div className={styles.scheduleCheckbox}>
                <Checkbox
                  label=''
                  checked={checkboxStates.schedule}
                  onChange={() => setCheckboxStates((prev) => ({ ...prev, schedule: !checkboxStates.schedule }))}
                  disabled={saving}
                />
              </div>
              <FormControl label='Schedule' disabled={!checkboxStates.schedule}>
                <ScheduleFormControl
                  validation={{ displayName: { hasError: false, errorMessage: '' } }}
                  opened={true}
                  scheduleLink={formState.schedule}
                  schedules={schedules}
                  areSchedulesLoading={schedulesLoading}
                  onChange={(link) => setFormState((prev) => ({ ...prev, schedule: link || undefined }))}
                  // if this undefined is removed the field will not clear ^
                  disabled={!checkboxStates.schedule || saving}
                  isInModal
                  fullWidth
                />
              </FormControl>
            </div>
          )}
          <div className={styles.formRow}>
            <Checkbox
              label=''
              checked={checkboxStates.effectiveDates}
              onChange={() =>
                setCheckboxStates((prev) => ({ ...prev, effectiveDates: !checkboxStates.effectiveDates }))
              }
              disabled={saving}
            />

            <EffectiveDates
              effectiveDates={formState.effectiveDates}
              onChangeStartDate={(sd) =>
                setFormState((prev) => ({ ...prev, effectiveDates: { start: sd, end: prev.effectiveDates?.end } }))
              }
              onChangeEndDate={(ed) =>
                setFormState((prev) => ({ ...prev, effectiveDates: { end: ed, start: prev.effectiveDates?.start } }))
              }
              isInModal
              disabled={!checkboxStates.effectiveDates || saving}
              disabledLabel={!checkboxStates.effectiveDates}
            />
          </div>

          {!isModifier && (
            <div className={styles.formRow}>
              <Checkbox
                label=''
                checked={checkboxStates.popular}
                onChange={() => setCheckboxStates((prev) => ({ ...prev, popular: !checkboxStates.popular }))}
                disabled={saving}
              />
              <FormControl
                label='Popular Item'
                disabled={!checkboxStates.popular}
                additionalStyles={styles.populatItemFormControl}
              >
                <Checkbox
                  disabled={!checkboxStates.popular || saving}
                  label='Add to popular item list'
                  checked={formState.isPopular || false}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setFormState((prev) => ({ ...prev, isPopular: e.target.checked }));
                  }}
                />
              </FormControl>
            </div>
          )}

          {showPosItemEdit && (
            <div className={styles.formRow}>
              <Checkbox
                label=''
                checked={checkboxStates.posItem}
                onChange={() => setCheckboxStates((prev) => ({ ...prev, posItem: !checkboxStates.posItem }))}
                disabled={saving}
              />
              <FormControl label='POS Item' disabled={!checkboxStates.posItem || saving}>
                <PosMenuItemSelect
                  initialOption={{ value: '', label: 'Select POS Item' }}
                  selectedPosMenuItemId={''}
                  companyId={companyId}
                  locationId={items[0].locationId}
                  isModifier={isModifier}
                  isInModal
                  disabled={!checkboxStates.posItem || saving}
                  onChange={(option?: IPosMenuItemOption) => {
                    setFormState((prev) => ({ ...prev, posItemId: option?.value }));
                  }}
                />
              </FormControl>
            </div>
          )}
        </Form>
      </ModalBody>
      <ModalFooter
        primaryLabel='Save Changes'
        primaryActionHandler={() => onSaveChanges()}
        disablePrimary={Object.values(checkboxStates).every((section) => !section)}
        secondaryLabel='Cancel'
        secondaryActionHandler={() => dispatch(setBulkLocationEditModalVisible(false))}
        loading={saving}
      />
    </Modal>
  );
};

export default BulkLocationEditModal;
