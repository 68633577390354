import React, { useState } from 'react';
import { PanelLayout, Panel } from 'components/PanelLayout';
import Button from 'components/Button/Button';
import InfoCard from 'components/InfoCard/InfoCard';
import InfoCardList from 'components/InfoCard/InfoCardList';
import style from './ServerFlowPage.module.scss';
import { Form, FormControl } from 'components/Form';
import TextInput from 'components/TextInput/TextInput';
import Checkbox from 'components/Checkbox/Checkbox';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { DeleteConfirmationModal, Modal, ModalBody, ModalFooter, ModalHeader } from 'components/Modal';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import InfoCardColumn from 'components/InfoCard/InfoCardColumn';
import { ILocationPrinterConfig, IPrinter } from '@ready/dashboardv2api.contracts';
import Toggle from 'components/Toggle/Toggle';
import { v4 as uuid } from 'uuid';
import LinkButton from 'components/LinkButton/LinkButton';

type PrinterSettingsPanelProps = {
  saving: boolean;
};

export const PrinterSettingsPanel = ({ saving = false }: PrinterSettingsPanelProps) => {
  const { getValues: getPrinterConfigValues, setValue: setPrinterConfigValue } =
    useFormContext<ILocationPrinterConfig>();
  const printers = getPrinterConfigValues().printers;

  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isNew, setIsNew] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset,
  } = useForm<IPrinter>();

  const handleSave = handleSubmit(async (formPrinter: IPrinter) => {
    if (isNew) {
      setPrinterConfigValue('printers', [...printers, formPrinter], { shouldDirty: true });
    } else {
      const updatedPrinters = printers.map((printer) => printer.id === getValues('id') ? { ...printer, ...formPrinter } : printer
      );
      setPrinterConfigValue('printers', updatedPrinters, { shouldDirty: true });
      setShowFormModal(false);
    }

    setShowFormModal(false);
  });
  
  return (
    <>
      <PanelLayout>
        <Panel
          fullContentArea
          title='Printers'
          subTitleComponent={<>Adding printers will allow users to print receipts from ServerFlow.</>}
          headerControls={
            <div style={{ flexDirection: 'row', display: 'flex', gap: 24 }}>
              <Controller
                name='enabled'
                render={({ field }) => <Toggle onChange={field.onChange} checked={field.value} disabled={saving} />}
              />
              <Button
                disabled={saving}
                label='+ Add Printer'
                variant='secondary'
                onClick={() => {
                  setIsNew(true);
                  reset(getNewPrinterValues());
                  setShowFormModal(true);
                }}
              />
            </div>
          }
        >
          <InfoCardList additionalClassName={style.infoList} noSearchResults={printers.length === 0}>
            {printers.map((printer, i) => (
              <div
                key={uuid()}
                onClick={() => {
                  if (saving) return;
                  setIsNew(false);
                  reset(printer);
                  setShowFormModal(true);
                }}
              >
                <InfoCard additionalStyles={style.printerCard}>
                  <InfoCardColumn> {printer.name}</InfoCardColumn>
                  <InfoCardColumn>
                    <StatusIndicator active={printer.enabled} activeLabel='Enabled' inactiveLabel='Disabled' />
                  </InfoCardColumn>
                </InfoCard>
              </div>
            ))}
          </InfoCardList>
        </Panel>
      </PanelLayout>

      {showFormModal && (
        <Modal setShowModal={setShowFormModal}>
          <div className={style.printerModal}>
            <ModalHeader headerLabel={`${isNew ? 'Add' : 'Edit'} Printer`} setShowModal={setShowFormModal} />
            {!isNew && (
              <div className={style.deleteButton}>
                <LinkButton
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                >
                  Delete Printer
                </LinkButton>
              </div>
            )}
          </div>
          <ModalBody>
            <Form isModalForm>
              <FormControl label='Enabled'>
                <Controller
                  control={control}
                  name='enabled'
                  render={({ field }) => <Checkbox label='' checked={field.value} {...field} />}
                />
              </FormControl>

              <FormControl label='Name *' withError={!!errors.name} errorMessage={errors.name?.message}>
                <TextInput
                  {...register('name', { required: 'Required field.' })}
                  placeholder='e.g. Main Printer'
                  withError={!!errors.name}
                />
              </FormControl>
              <FormControl label='Model *' withError={!!errors.model} errorMessage={errors.model?.message}>
                <TextInput
                  {...register('model', { required: 'Required field.' })}
                  placeholder='e.g. Epson TM88V'
                  withError={!!errors.model}
                />
              </FormControl>
              <FormControl label='IP Address *' withError={!!errors.ipAddress} errorMessage={errors.ipAddress?.message}>
                <TextInput
                  {...register('ipAddress', {
                    validate: {
                      required: (val) => {
                        const type = getValues('type');
                        if (!val && type === 'network') {
                          return 'Required for network printers.';
                        }
                        return true;
                      },
                    },
                  })}
                  withError={!!errors.ipAddress}
                />
              </FormControl>
              <FormControl label='Port *' withError={!!errors.port} errorMessage={errors.port?.message}>
                <TextInput
                  type='number'
                  {...register('port', {
                    validate: {
                      required: (val) => {
                        const type = getValues('type');
                        if (!val && type === 'network') {
                          return 'Required for network printers.';
                        }
                        return true;
                      },
                    },
                  })}
                  withError={!!errors.port}
                />
              </FormControl>
            </Form>
          </ModalBody>
          <ModalFooter
            primaryLabel={isNew ? 'Add' : 'Edit'}
            primaryActionHandler={handleSave}
            disablePrimary={false}
            secondaryLabel={'Cancel'}
            secondaryActionHandler={() => {
              setShowFormModal(false);
            }}
          />
        </Modal>
      )}

      {showDeleteModal && (
        <DeleteConfirmationModal
          setShowModal={setShowDeleteModal}
          item='Printer'
          itemName={getValues('name')}
          loading={saving}
          handleDelete={() => {
            const updatedPrinters = printers.filter((x) => x.id !== getValues('id'));
            setPrinterConfigValue('printers', updatedPrinters, { shouldDirty: true });
            setShowDeleteModal(false);
            setShowFormModal(false);
          }}
        />
      )}
    </>
  );
};

const getNewPrinterValues = (): IPrinter => {
  return {
    id: uuid(),
    brand: 'epson',
    type: 'network',
    model: '',
    name: '',
    enabled: true,
    port: 9100,
    ipAddress: '',
    macAddress: '',
  };
};
