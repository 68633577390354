import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { ChargebackPage } from './pages/chargeback/ChargebackPage';

export const FinancialServicesRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/chargeback`} component={ChargebackPage} />
    </Switch>
  );
};
