import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TablesTab } from '../../../enums/tables.enum';
import TablesLayoutContent from '../TablesDetails/TablesLayoutContent';
import { ContextParams } from '../../../types/ContextParams.interface';
import { useDispatch, useSelector } from 'react-redux';
import { paymentProcessingSelector } from '../../redux/selectors/PaymentProcessingSelector';
import { fetchLocation } from '../../redux/actions/paymentProcessingActions';
import {
  ServerFlowSettingsPanel,
  ServerFlowSettingsPanelType,
} from './serverFlowSettingsPanel/ServerFlowSettingsPanel';
import FormActionBar from 'components/FormActionBar/FormActionBar';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppSelector } from 'redux/store';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { getClientServerFlowSettingsThunk, saveClientServerFlowSettingsThunk } from './redux/settings/thunk';
import { ILocationPrinterConfig, ILocationServerFlow, PaymentProcessorProvider } from '@ready/dashboardv2api.contracts';
import { ReadyPaymentsPanel } from './readyPayments/ReadyPaymentsPanel';
import { SimplePanelLayout } from 'components/PanelLayout/SimplePanelLayout';
import styles from './ServerFlowPage.module.scss';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { getTerminalsThunk } from './redux/readyPayments/thunk';
import { PrinterSettingsPanel } from './PrinterSettingsPanel';
import { NavigationConfirmationDialog } from 'components/NavigationConfirmationDialog';

export const ServerFlowPage = () => {
  const { contextId: companyId, locationId } = useParams<ContextParams>();
  const dispatch = useDispatch();
  const { location } = useSelector(paymentProcessingSelector);

  useEffect(() => {
    dispatch(fetchLocation(companyId, locationId));
    dispatch(getClientServerFlowSettingsThunk({ companyId, locationId }));
  }, [companyId, dispatch, locationId]);

  useEffect(() => {
    if (location?.paymentProcessor === PaymentProcessorProvider.Adyen) {
      dispatch(getTerminalsThunk({ locationId, companyId }));
    }
  }, [dispatch, companyId, locationId, location?.paymentProcessor]);

  const showReadyPaymentsPanel = location?.paymentProcessor === PaymentProcessorProvider.Adyen;

  const locationName = location && location?.id === locationId ? location.name : '';
  const {
    serverFlowConfig: { serverFlowCache, loading: serverFlowConfigPanelIsLoading },
    readyPayments: { loading: readyPaymentsPanelIsLoading },
  } = useAppSelector((state) => state.location.serverFlow);

  const isLoading = serverFlowConfigPanelIsLoading || readyPaymentsPanelIsLoading;

  const [isSaving, setIsSaving] = useState(false);

  const {
    handleSubmit,
    formState: { errors, defaultValues, isDirty: settingsPanelDirty },
    control,
    reset: settingsPanelReset,
  } = useForm<ServerFlowSettingsPanelType>({
    values: {
      serverFlowFeatureEnabled: serverFlowCache?.serverFlowFeatureEnabled ?? false,
      inactivityLockEnabled: serverFlowCache?.inactivityLockEnabled ?? false,
      screenTimeoutDurationInMinutes: serverFlowCache?.screenTimeoutDurationInMinutes ?? 0,
      chatAssistantFeatureEnabled: serverFlowCache?.chatAssistantFeatureEnabled ?? false,
      feedbackEnabled: serverFlowCache?.feedbackEnabled ?? false,
    },
  });

  const printerConfigForm = useForm<ILocationPrinterConfig>({
    values: serverFlowCache?.printerConfig,
    defaultValues: { enabled: false, printers: [] },
  });

  const onSubmit = async (data: ILocationServerFlow) => {
    setIsSaving(true);
    await dispatch(
      saveClientServerFlowSettingsThunk({
        companyId,
        locationId,
        settings: {
          serverFlowFeatureEnabled: data.serverFlowFeatureEnabled,
          inactivityLockEnabled: data.inactivityLockEnabled,
          screenTimeoutDurationInMinutes: Number(data.screenTimeoutDurationInMinutes),
          chatAssistantFeatureEnabled: data.chatAssistantFeatureEnabled,
          feedbackEnabled: data?.feedbackEnabled,
          printerConfig: printerConfigForm.getValues(),
        },
      })
    );
    setIsSaving(false);
     dispatch(setFormIsDirty(false));
  };

  const isDirty = settingsPanelDirty || printerConfigForm.formState.isDirty;
  const reset = () => {
    settingsPanelReset();
    printerConfigForm.reset();
  };

  return (
    <>
      <NavigationConfirmationDialog shouldBlockNav={isDirty} />
      <FormActionBar
        actionBarVisible={isDirty}
        handleFormSubmit={handleSubmit(onSubmit)}
        resetForm={() => {
          reset();
          dispatch(setFormIsDirty(false));
        }}
        isFormProcessing={isSaving}
      />
      <TablesLayoutContent
        companyId={companyId}
        locationId={locationId}
        locationName={locationName}
        tab={TablesTab.ServerFlow}
      >
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <SimplePanelLayout customStyle={styles.contentContainer}>
            <ServerFlowSettingsPanel
              posType={location?.posSystemType}
              defaultValues={defaultValues}
              control={control}
              isSaving={isSaving}
              errors={errors}
            />

            <FormProvider {...printerConfigForm}>
              <PrinterSettingsPanel saving={isSaving} />
            </FormProvider>

            {showReadyPaymentsPanel && <ReadyPaymentsPanel />}
          </SimplePanelLayout>
        )}
      </TablesLayoutContent>
    </>
  );
};
