import { createAsyncThunk } from '@reduxjs/toolkit';

import config from 'config';
import executeApiAsync from 'services/utils/executeApiAsync';

import { IOBPChargebackTokenInfo } from '@ready/dashboardv2api.contracts';
import { pageErrorState } from 'redux/actions/uiActions/responseStateActions';

const getChargebackTokenInfo = async ({ companyId }: { companyId: string }): Promise<IOBPChargebackTokenInfo> => {
  const url = `${config.readyDashboardApiEndpoint}/companies/${companyId}/chargeback/tokenInfo`;
  return executeApiAsync<IOBPChargebackTokenInfo>(url);
};

export const getChargebackTokenInfoThunk = createAsyncThunk(
  'financialServices/chargeback/fetchTokenInfo',
  async (args: { companyId: string }, { dispatch }) => {
    const LOCAL_STORAGE_CHARGEBACK_TOKEN_INFO = 'chargebackTokenInfo';
    const storedTokenInfo = localStorage.getItem(LOCAL_STORAGE_CHARGEBACK_TOKEN_INFO);

    // If the token exists and hasn't expired, return the tokenInfo in local storage
    if (storedTokenInfo) {
      const tokenInfo: IOBPChargebackTokenInfo = JSON.parse(storedTokenInfo);

      const currentTime = Math.floor(new Date().getTime() / 1000);
      if (tokenInfo.expiresAt > currentTime) {
        return tokenInfo;
      }
    }

    try {
      const tokenInfo = await getChargebackTokenInfo({ companyId: args.companyId });
      localStorage.setItem(LOCAL_STORAGE_CHARGEBACK_TOKEN_INFO, JSON.stringify(tokenInfo));
      return tokenInfo;
    } catch (error) {
      dispatch(pageErrorState(error.status, error.message || 'Failed to fetch chargeback token info'));
      throw error;
    }
  }
);
