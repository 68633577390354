import React, { useEffect } from 'react';
import { usePageTitle } from 'hooks';
import { Iframe } from 'components/Iframe/Iframe';
import styles from './ChargebackPage.module.scss';
import { useAppSelector } from 'redux/store';
import { useParams } from 'react-router-dom';
import { getChargebackTokenInfoThunk } from './redux/thunk';
import { useDispatch } from 'react-redux';
import { ContextParams } from 'types/ContextParams.interface';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

export const ChargebackPage = () => {
  usePageTitle('Chargebacks');
  const {
    chargeback: { loading, chargeback },
  } = useAppSelector((state) => state.location.financialServices);
  const dispatch = useDispatch();
  const { contextId: companyId } = useParams<ContextParams>();

  useEffect(() => {
    dispatch(getChargebackTokenInfoThunk({ companyId }));
  }, [companyId, dispatch]);

  return (
    <div className={styles.pageContainer}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.iframeContainer}>
          <Iframe url={chargeback?.iframeUrl ?? ''} title='chargeback' />
        </div>
      )}
    </div>
  );
};
