import { connect } from 'react-redux';
import { FeedbackReportPage } from '../reports/feedback/FeedbackReport';
import { getFirstAvailableSidebarOption } from '../utils/sidebar.utils';
import { ISession } from '../redux/initialStates/session/session';
import { Switch, Route, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { UserRoutes } from './companyRoutes/UserRoutes';
import CompanyContext from '../pages/CompanyContext/CompanyContext';
import CompanyLocations from '../companyLocations/Router';
import CompanySettings from '../companySettings/Router';
import LocationsRouter, { Routes as LocationsRoutes } from '../menus/LocationsRouter';
import MenuBuilderRouter, { Routes as MenuBuilderRoutes } from '../menus/MenuBuilderRouter';
import NotFound from '../pages/NotFound/NotFound';
import OrderDetailPage from '../pages/Orders/OrderDetailPage';
import OrdersListPageRoutes from '../pages/Orders/OrdersListPageRoutes';
import OrdersLocationPickerPage from '../pages/Orders/OrdersLocationsPickerPage';
import React from 'react';
import SchedulesRouter from '../menus/SchedulesRouter';
import SecurityRoleViewPage from '../securityRoles/router';
import ServerAssignmentEditPage from '../serverAssignment/ServerAssignmentEditPage';
import ServerAssignmentLocationPickerPage from '../serverAssignment/ServerAssignmentLocationPickerPage';
import TagsBadgesRouter from '../tagsBadges/Router';
import TransactionDetailPage from '../pages/Transactions/TransactionDetailPage';
import TransactionsListPage from '../pages/Transactions/TransactionsListPage';
import { EmailMarketingListPage } from '../reports/emailMarketingList/EmailMarketingList';
import { checkFeatureToggle } from '../utils/featureToggle/featureToggle';
import { ChecksReportPage } from '../reports/checks/report/Page';
import { PromoCodeSummaryPage } from '../reports/promoCodeSummary/PromoCodeSummary';
import { SharedMenuItemsRouter, SharedMenuItemsRoutes } from 'sharedMenuItems/Router';
import { CheckPage } from '../reports/checks/details/Page';
import { LocationPerformancePage } from '../reports/locationPerformance/LocationPerformance';
import { PaymentsReportPage } from 'reports/payments/PaymentsReportPage';
import { PayoutHistoryReportPage } from 'reports/payoutHistory/PayoutHistoryReportPage';
import { PayoutHistoryDetailsPage } from 'reports/payoutHistoryDetails/PayoutHistoryDetailsPage';
import { FinancialServicesRouter } from 'financialServices/Router';

export interface CompaniesRoutesProps {
  session: ISession;
}

const CompaniesRoutes = (props: CompaniesRoutesProps) => {
  const { session } = props;
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { push } = useHistory();
  // redirect logic
  useRedirect(session, pathname, push);

  return (
    <Switch>
      <Route path={`${path}/schedules`} component={SchedulesRouter} />
      <Route path={`${path}${LocationsRoutes.getTemplate()}`} component={LocationsRouter} />
      <Route path={`${path}${MenuBuilderRoutes.getTemplate()}`} component={MenuBuilderRouter} />
      <Route path={`${path}${SharedMenuItemsRoutes.getTemplate()}`} component={SharedMenuItemsRouter} />

      <Route path={`${path}/tagsBadges`} component={TagsBadgesRouter} />
      <Route path={`${path}/users`} component={UserRoutes} />
      {/* company locations */}
      <Route path={`${path}/companyLocations`} component={CompanyLocations} />
      <Route path={`${path}/financialServices`} component={FinancialServicesRouter} />
      {/* transactions */}
      <Route path={`${path}/locations/:locationId/transactions/:id`} component={TransactionDetailPage} />
      <Route path={`${path}/transactions`} component={TransactionsListPage} />

      <Route path={`${path}/payments`} component={PaymentsReportPage} />\
      {/** hide the daily totals summary report for now, as the payout history report is similar */}
      {/* <Route path={`${path}/dailyTotalsSummary`} component={DailyTotalsReportPage} /> */}
      <Route path={`${path}/payoutHistory/:id`} component={PayoutHistoryDetailsPage} />
      <Route path={`${path}/payoutHistory`} component={PayoutHistoryReportPage} />

      {/* feedback */}
      <Route path={`${path}/feedback`} component={FeedbackReportPage} />
      {/* email marketing list */}
      <Route path={`${path}/email_marketing_list`} component={EmailMarketingListPage} />
      {/* checks */}
      {checkFeatureToggle('app_checksReport') ? <Route path={`${path}/checks`} component={ChecksReportPage} /> : null}
      {checkFeatureToggle('app_checkPage') ? (
        <Route path={`${path}/locations/:locationId/checks/:checkId`} component={CheckPage} />
      ) : null}
      {/* promo code usage */}
      {checkFeatureToggle('app_promoCodeUsageReport') ? (
        <Route path={`${path}/promo_code_usage`} component={PromoCodeSummaryPage} />
      ) : null}
      {/* location performance report */}
      {checkFeatureToggle('app_locationPerformanceReport') ? (
        <Route path={`${path}/location_performance`} component={LocationPerformancePage} />
      ) : null}
      {/* security roles */}
      <Route path={`${path}/securityRoles`} component={SecurityRoleViewPage} />
      {/* orders */}
      <Route path={`${path}/locations/:locationId/orders/day/:day`} component={OrdersListPageRoutes} />
      <Route path={`${path}/locations/:locationId/orders/:orderId`} component={OrderDetailPage} />
      <Route path={`${path}/orders`} component={OrdersLocationPickerPage} />
      {/* table assignment */}
      <Route path={`${path}/locations/:locationId/serverAssignment`} component={ServerAssignmentEditPage} />
      <Route path={`${path}/serverAssignment`} component={ServerAssignmentLocationPickerPage} />
      {/* company settings */}
      <Route path={`${path}/companySettings`} component={CompanySettings} />
      {/* company no permissions */}
      <Route exact path={`${path}/`} component={CompanyContext} />
      <Route path='*' component={NotFound} />
    </Switch>
  );
};

const useRedirect = (session: ISession, pathname: string, push: (path: string) => void) => {
  React.useEffect(() => {
    //if (session.contextSession.invalidContext) return;
    if (session.sidebar.optionsSet) {
      // get sidebarOptions
      const sidebarOptions = session.sidebar.options;
      const pathSegments = pathname.split('/');
      const resourceIndex = 3;
      const resourceInUrl = !!pathSegments[resourceIndex];

      if (sidebarOptions.length > 0) {
        if (!resourceInUrl && !session.contextSession.invalidContext) {
          const contextType = 'company';
          const firstSidebarOption = getFirstAvailableSidebarOption(sidebarOptions, contextType);
          const firstOptionUrl = firstSidebarOption ? firstSidebarOption.url : '';
          const redirectPrefix = `/companies/${session.contextSession.id}`;
          const redirectUrl = `${redirectPrefix}${firstOptionUrl}`;
          push(redirectUrl);
        }
      } else {
        // if no option available and the user tries to access a resource
        if (resourceInUrl) {
          // dispatch(updateUnauthorizedError(true));
          // push('/unauthorized');
          // TODO: hook the new errors page up here
        }
      }
    }
  }, [pathname, session.sidebar, session.contextSession.invalidContext, session.contextSession.id, push]);
};

const mapStateToProps = (state: any) => ({
  session: state.session,
});

export default connect(mapStateToProps)(CompaniesRoutes);
