import React, { useRef } from 'react';

interface Props extends React.HTMLProps<HTMLIFrameElement> {
  url: string;
  title: string;
}

export const Iframe = (props: Props) => {
  const { url, width = '100%', height = '100%', style, title = '' } = props;
  const iframeRef = useRef<HTMLIFrameElement>(null);

  return <iframe ref={iframeRef} title={title} src={url} width={width} height={height} style={style} frameBorder='0' />;
};
