import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { getChargebackTokenInfoThunk } from './thunk';
import { IOBPChargebackTokenInfo } from '@ready/dashboardv2api.contracts';

type chargebackState = {
  chargeback?: IOBPChargebackTokenInfo;
  tokenHasExpired?: boolean;
  loading: boolean;
};

export const chargebackInitialState: chargebackState = {
  loading: false,
};

export const chargebackSlice = createSlice({
  name: 'financialServices/chargeback',
  initialState: chargebackInitialState,

  reducers: {},

  extraReducers: (builder: ActionReducerMapBuilder<chargebackState>) => {
    builder.addCase(getChargebackTokenInfoThunk.pending, (state: chargebackState) => {
      state.loading = true;
    });
    builder.addCase(
      getChargebackTokenInfoThunk.fulfilled,
      (state: chargebackState, action: { payload: IOBPChargebackTokenInfo | undefined }) => {
        if (action.payload) {
          state.chargeback = action.payload;
          state.loading = false;
        }
      }
    );
    builder.addCase(getChargebackTokenInfoThunk.rejected, (state: chargebackState) => {
      state.loading = false;
    });
  },
});
