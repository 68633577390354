import { combineReducers } from 'redux';
import { detailsPanelReducer } from './PanelDetailsReducer';
import { featuresPanelReducer } from './PanelFeaturesReducer';
import { posSettingsPanelReducer } from './PanelPOSSettingsReducer';
import { paymentProcessingPanelReducer } from './PanelPaymentProcessingReducer';
import { brandingPanelReducer } from './PanelBrandingReducer';
import { gratuityPanelReducer } from './GratuityPanel/reducers';
import { emailReceiptsPanelReducer } from './PanelEmailReceiptsReducer';
import { serviceFactorsPanelReducer } from './PanelServiceFactorsReducer';
import { stationAppPanelReducer } from './PanelStationAppReducer';
import { locationBillingReducer } from './LocationBillingReducer';
import { lastEditReducer } from './LastEditReducer';
import { locationsReducer } from './LocationsReducer';
import { posLoyaltyPanelReducer } from './PanelLoyaltyReducer';
import { locationPromoCodesReducer } from './LocationPromoCodesReducer';
import { promoCodesReducer } from './PromoCodesReducer';
import { newsletterPanelReducer } from './PanelNewsletterReducer';
import { companyIntegrationsReducer } from './companyIntegrationsReducer';
import { paymentProcessingTabReducer } from 'locations/components/PaymentProcessingTab/reducer';
import { orderHoursSlice } from 'companyLocations/redux/reducers/orderHours/slice';
import { orderMessagingConfigSlice } from 'companyLocations/redux/reducers/orderMessagingConfig/slice';
import { serverFlowReducer } from 'companyLocations/pages/serverFlow/redux/index';
import { financialServicesReducer } from 'financialServices/reducer';

export const locationReducer = combineReducers({
  detailsPanel: detailsPanelReducer,
  featuresPanel: featuresPanelReducer,
  posSettingsPanel: posSettingsPanelReducer,
  paymentProcessingPanel: paymentProcessingPanelReducer,
  brandingPanel: brandingPanelReducer,
  gratuityPanel: gratuityPanelReducer,
  newsletterPanel: newsletterPanelReducer,
  emailReceiptsPanel: emailReceiptsPanelReducer,
  serviceFactorsPanel: serviceFactorsPanelReducer,
  stationAppPanel: stationAppPanelReducer,
  billingPanel: locationBillingReducer,
  lastEdit: lastEditReducer,
  locationsList: locationsReducer,
  posLoyaltyPanelInitialState: posLoyaltyPanelReducer,
  locationPromoCodes: locationPromoCodesReducer,
  promoCodes: promoCodesReducer,
  companyIntegrations: companyIntegrationsReducer,

  // new structure for location page statem -- split by tabs
  paymentProcessingTab: paymentProcessingTabReducer,
  orderHours: orderHoursSlice.reducer,
  orderMessagingConfig: orderMessagingConfigSlice.reducer,
  serverFlow: serverFlowReducer,
  financialServices: financialServicesReducer,
});
