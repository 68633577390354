import { IBulkEditMenuItem } from '@ready/menu.core';
import { Body, BodySkeleton } from 'components/TableV2/Body/Body';
import { getWrappedCellsWithMobileLayout } from 'components/TableV2/Cell/Cell';
import { HeaderCell } from 'components/TableV2/Cell/HeaderCell';
import { TableHeader } from 'components/TableV2/TableHeader/TableHeader';
import { TableV2 } from 'components/TableV2/TableV2';
import { useResponsiveBreakpoint } from 'hooks';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { selectTableState } from 'sharedMenuItems/redux/selectors';
import {
  setUnassignLocationModalVisible,
  setBulkUnassignLocationModalVisible,
} from 'sharedMenuItems/redux/table/tableSlice';
import BulkLocationEditModal from '../modals/BulkLocationEditModal';
import EditLocationSettingsModal from '../modals/EditLocationSettingsModal';
import {
  LocationNameCell,
  EnabledStatusCell,
  POSItemCell,
  PriceLevelCell,
  ScheduleLinkCell,
  EffectiveDatesCell,
  PopularCell,
  UnassignCell,
  CheckboxCell,
  MobileToolbarHeader,
} from './Cells';
import { UnassignLocationModal } from 'sharedMenuItems/components/unassignLocationModal/UnassignLocationModal';
import { updateAssignedLocationsThunk, getTemplateChildMenuItems } from '../../redux/thunks';
import { useParams } from 'react-router-dom';
import { ContextParams } from 'types/ContextParams.interface';
import BulkUnassignLocationModal from 'sharedMenuItems/components/bulkUnassignLocationModal/BulkUnassignLocationModal';
import styles from './AssignedLocationsTable.module.scss';

interface Props {
  items: Array<IBulkEditMenuItem>;
  loading: boolean;
  resetPageFilters?: () => void;
  hasPageDefaultFilters?: boolean;
}

const AssignedLocationsTable = ({ items, loading, resetPageFilters, hasPageDefaultFilters = false }: Props) => {
  const { contextId: companyId, id: itemId } = useParams<ContextParams>();
  const {
    isUnassignLocationModalVisible,
    isEditLocationSettingsModalVisible,
    isBulkLocationEditModalVisible,
    isBulkUnassignLocationModalVisible,
    rowData,
    checkCells,
  } = useAppSelector(selectTableState);
  const { isMobile } = useResponsiveBreakpoint();
  const dispatch = useAppDispatch();
  const { itemCache } = useAppSelector((state) => state.sharedMenuItems.item);
  const [updating, setUpdating] = useState<boolean>();
  const [saving, setSaving] = useState<boolean>();
  const isModifer = itemCache?.itemType === 'option';
  const columns = isModifer ? modifierColumns : itemColumns;
  const checkedLocationIds = Object.keys(checkCells).filter((locationId) => checkCells[locationId]);

  if (isMobile && !!columns.indexOf('mobileToolbarHeader')) {
    columns.unshift('mobileToolbarHeader');
  }

  const onUnassign = async () => {
    if (!rowData) return;

    setUpdating(true);
    await dispatch(
      updateAssignedLocationsThunk({
        companyId,
        itemId,
        assignToLocations: [],
        unassignFromLocations: [rowData.locationId],
      })
    );

    setUpdating(false);

    if (hasPageDefaultFilters) {
      dispatch(
        getTemplateChildMenuItems({
          companyId,
          itemId: itemId,
        })
      );
    } else {
      resetPageFilters && resetPageFilters();
    }
    dispatch(setUnassignLocationModalVisible(false));
  };

  const onBulkUnassign = async () => {
    setSaving(true);
    await dispatch(
      updateAssignedLocationsThunk({
        companyId,
        itemId,
        assignToLocations: [],
        unassignFromLocations: checkedLocationIds,
      })
    );
    setSaving(false);
    dispatch(setBulkUnassignLocationModalVisible(false));
  };

  return (
    <TableV2>
      {isBulkUnassignLocationModalVisible && (
        <BulkUnassignLocationModal
          itemType={isModifer ? 'Modifier' : 'Item'}
          itemName={itemCache?.displayName}
          totalCheckedLocations={checkedLocationIds.length}
          loading={saving}
          setShowModal={(showModal) => dispatch(setBulkUnassignLocationModalVisible(showModal))}
          onBulkUnassign={onBulkUnassign}
        />
      )}
      {isUnassignLocationModalVisible && (
        <UnassignLocationModal
          itemType={isModifer ? 'Modifier' : 'Item'}
          itemName={itemCache?.displayName}
          locationName={rowData?.locationName}
          onUnassign={onUnassign}
          loading={updating}
          setShowModal={(showModal) => dispatch(setUnassignLocationModalVisible(showModal))}
        />
      )}
      {isEditLocationSettingsModalVisible && <EditLocationSettingsModal />}
      {isBulkLocationEditModalVisible && (
        <BulkLocationEditModal resetPageFilters={resetPageFilters} />
      )}
      <TableHeader cells={headerCells} data={headerColumns} columns={columns} />
      {!loading ? (
        <Body cells={cells} hiddenColumns={{}} rowKey={'_id'} data={items} columns={columns} />
      ) : (
        <BodySkeleton rowsNumber={50} columns={columns} />
      )}
    </TableV2>
  );
};

const headerColumns = {
  checkboxCell: '',
  locationName: 'Location Name',
  enabled: 'Status',
  linkedPosItem: 'Linked POS Item',
  price: 'Price',
  schedule: 'Schedule',
  effectiveDates: 'Effective Date',
  popular: 'Popular',
  unassign: '',
};

const headerCells = {
  checkboxCell: CheckboxCell,
  locationName: HeaderCell,
  enabled: HeaderCell,
  linkedPosItem: HeaderCell,
  price: HeaderCell,
  schedule: HeaderCell,
  effectiveDates: HeaderCell,
  popular: HeaderCell,
  unassign: HeaderCell,
};

const cells = getWrappedCellsWithMobileLayout(
  {
    mobileToolbarHeader: MobileToolbarHeader,
    checkboxCell: CheckboxCell,
    locationName: LocationNameCell,
    enabled: EnabledStatusCell,
    linkedPosItem: POSItemCell,
    price: PriceLevelCell,
    schedule: ScheduleLinkCell,
    effectiveDates: EffectiveDatesCell,
    popular: PopularCell,
    unassign: UnassignCell,
  },
  headerColumns,
  styles.assignedLocationsCellsWrapper
);

const itemColumns = [
  'checkboxCell',
  'locationName',
  'enabled',
  'linkedPosItem',
  'price',
  'schedule',
  'effectiveDates',
  'popular',
  'unassign',
];

const modifierColumns = [
  'checkboxCell',
  'locationName',
  'enabled',
  'linkedPosItem',
  'price',
  'effectiveDates',
  'unassign',
];

// using temp interface here to add inStock because original interface doesn't have it yet.
export interface IBulkEditMenuItemTemp extends IBulkEditMenuItem {
  inStock: boolean;
}

export { AssignedLocationsTable };
